.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 55px;
  background-color: white;
  background-image: url('assets/transicsLogo.png');
  background-repeat: no-repeat;
  border-radius: 8px;
  margin: 30px;
  margin-top: 15px;
}

.closed-logo {
  height: 55px;
  /* background-color: white; */
  border-radius: 8px;
  margin: 30px;
  margin-top: 15px;
}

.app-content {
  margin: 20px 15px;
  background: #fff;
  min-height: 280;
}

.app-content-phone {
  margin: 5px 3px;
  background: #fff;
  min-height: 280;
  overflow: hidden;
}

.app-title-phone {
  display: none;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

button[data-test='forgot-password-submit-button'],
button[data-test='forgot-password-send-code-button'],
button[data-test='sign-in-sign-in-button'] {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16), 0 2px 4px rgba(0, 0, 0, 0.23) !important;
}

button[data-test='forgot-password-submit-button']:disabled,
button[data-test='forgot-password-send-code-button']:disabled,
button[data-test='sign-in-sign-in-button']:disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
